<template>
    <base-section id="marketing">
        <v-container>
            <v-row class="accent" align="center">
                <template v-for="(card, i) in cards">
                    <v-col cols="12" lg="4" :key="`left-col-${i}`">
                        <v-card
                            v-if="card.textPosition === 'left'"
                            :color="card.color"
                            flat
                        >
                            <v-card-title>
                                <v-icon
                                    :color="
                                        card.dark
                                            ? 'blue-grey lighten-5'
                                            : 'primary'
                                    "
                                    size="36"
                                    >{{ card.headerIcon }}</v-icon
                                >
                            </v-card-title>
                            <v-card-text>
                                <base-info-card v-bind="card" />
                            </v-card-text>
                        </v-card>
                        <v-img v-else :src="card.image" />
                    </v-col>
                    <v-col cols="12" lg="8" :key="`right-col-${i}`">
                        <v-card
                            v-if="card.textPosition === 'right'"
                            :color="card.color"
                            class="pa-2"
                        >
                            <v-card-title>
                                <v-icon
                                    :color="
                                        card.dark
                                            ? 'blue-grey lighten-5'
                                            : 'primary'
                                    "
                                    size="80"
                                    >{{ card.headerIcon }}</v-icon
                                >
                            </v-card-title>
                            <v-card-text>
                                <base-info-card v-bind="card" />
                            </v-card-text>
                        </v-card>
                        <v-img v-else :src="card.image" />
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: "SectionMarketingAlt",

    data: () => ({
        cards: [
            {
                headerIcon: "mdi-gesture-tap",
                title: "ENGAGING LISTINGS",
                text: 'Rent Well graduates can easily review, sort, and find the listing that fits their needs on our platform. They can sort properties or available units by filtering for <ul class="my-2"><li>If a property is Rent Well Certified</li><li>Size of the unit</li><li>Number of bedrooms and bathrooms</li><li>Type of housing</li><li>Month rent prices</li><li>Location of the property</li></ul>With a click of a button Rent Well graduates can look at property info, a gallery of photos, screening criteria, maps of the location and surroundings, as well as send a message to the property manager about their interest in applying.',
                image: "https://pacific-screening.imgix.net/rw-apply-oregon/apply-oregon-listings.png",
                textPosition: "left",
                dark: true,
                color: "accent",
            },
        ],
    }),
};
</script>
